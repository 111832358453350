import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

import useClickOutside from '../../hooks/useClickOutside';
import s from './InputAutocomplete.module.scss';
import { highlightMatches } from './utils';

const InputAutocomplete = ({
  options,
  onChange,
  mode = 'single',
  onSelectOption,
  placeholder,
  name,
  editingValue,
}) => {
  const [isInputDirty, setIsInputDirty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, setValue] = useState('');
  const isShowOptions = options.length > 0 && isInputDirty && isOpen;

  const handleSelectOption = useCallback(
    (option) => {
      if (mode === 'multi') {
        const isSelected = selectedOptions.some(
          (selected) => selected.partner_id === option.partner_id,
        );

        if (isSelected) {
          setSelectedOptions(
            selectedOptions.filter((selected) => selected.partner_id !== option.partner_id),
          );
        } else {
          setSelectedOptions([...selectedOptions, option]);
          setValue('');
        }
      } else {
        setSelectedOptions([option]);
        setValue(`${option.partner_id} ${option.full_name || ''}`);
        setIsOpen(false);
        setIsInputDirty(false);
      }
    },
    [mode, onSelectOption, selectedOptions],
  );

  useEffect(() => {
    if (editingValue?.length === 1) {
      handleSelectOption(editingValue[0]);
    } else if (editingValue?.length > 1) {
      setSelectedOptions(editingValue);
    }
  }, [editingValue]);

  const handleDeleteOption = (option) => {
    setSelectedOptions((prevState) =>
      prevState.filter((selected) => selected.partner_id !== option.partner_id),
    );
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setIsInputDirty(true);
    setIsOpen(true);
    setValue(value);
    onChange(value);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
    setIsInputDirty(false);
  };

  const ref = useClickOutside(handleClickOutside);

  useEffect(() => {
    if (mode === 'multi') {
      onSelectOption(selectedOptions);
    } else if (selectedOptions[0]) {
      onSelectOption(selectedOptions[0]);
    }
  }, [mode, selectedOptions]);

  return (
    <div className={s.autocompleteWrapper} ref={ref}>
      <div className={s.autocompleteInputWrapper}>
        <div className={s.tags}>
          {mode === 'multi' &&
            selectedOptions?.map((item) => (
              <div className={s.tag} key={item?.id}>
                <span>{item.partner_id}</span>
                <button onClick={() => handleDeleteOption(item)}>
                  <CloseIcon width={18} height={18} />
                </button>
              </div>
            ))}

          <input
            type="text"
            onChange={handleChange}
            value={value}
            className={s.autocompleteInput}
            placeholder={placeholder}
          />
        </div>
      </div>

      {isShowOptions && (
        <div className={s.autocompleteOptionsContent}>
          <ul className={s.autocompleteOptionList}>
            {options?.map((option) => (
              <li
                key={option.partner_id}
                className={s.autocompleteOption}
                onClick={() => handleSelectOption(option)}>
                {mode === 'multi' && (
                  <input
                    type="checkbox"
                    name={name}
                    checked={
                      mode === 'multi'
                        ? selectedOptions.some(
                            (selected) => selected.partner_id === option.partner_id,
                          )
                        : selectedOptions[0]?.partner_id === option.partner_id
                    }
                    readOnly
                  />
                )}
                <p>{option.partner_id && highlightMatches(option.partner_id, value)}</p>
                <span>{`${option.full_name}`}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputAutocomplete;
