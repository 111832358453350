import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    FormGroup,
    Input,
    Spinner
} from 'reactstrap';
import { mockSupportService, mockStatuses, mockPriorities, mockCategories } from '../../mocks/supportData';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import './support.scss';

const TicketPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [ticket, setTicket] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        const loadTicket = async () => {
            try {
                const data = await mockSupportService.getTicket(id);
                if (!data) {
                    history.push('/support');
                    return;
                }
                setTicket(data);
            } catch (error) {
                console.error('Error loading ticket:', error);
                history.push('/support');
            } finally {
                setLoading(false);
            }
        };
        loadTicket();
    }, [id, history]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        setSending(true);
        try {
            await mockSupportService.addMessage(id, newMessage);
            const updatedTicket = await mockSupportService.getTicket(id);
            setTicket(updatedTicket);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setSending(false);
        }
    };

    const handleCloseTicket = async () => {
        try {
            await mockSupportService.closeTicket(id);
            const updatedTicket = await mockSupportService.getTicket(id);
            setTicket(updatedTicket);
        } catch (error) {
            console.error('Error closing ticket:', error);
        }
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'd MMM yyyy HH:mm', { locale: ru });
    };

    if (loading) {
        return (
            <div className="page-content">
                <Container fluid>
                    <div className="text-center">
                        <Spinner color="primary" />
                    </div>
                </Container>
            </div>
        );
    }

    if (!ticket) {
        return (
            <div className="page-content">
                <Container fluid>
                    <div className="text-center">
                        <p>{t('support_ticket_not_found')}</p>
                        <Button
                            color="primary"
                            onClick={() => history.push('/support')}
                        >
                            {t('support_back_to_list')}
                        </Button>
                    </div>
                </Container>
            </div>
        );
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>{t('support_ticket_details')}</title>
            </MetaTags>
            <Container fluid>
                <div className="page-header">
                    <div className="header-text">
                        <Button
                            color="link"
                            className="back-button p-0"
                            onClick={() => history.push('/support')}
                        >
                            <i className="bx bx-arrow-back"></i>
                        </Button>
                        <h4>{ticket.title}</h4>
                    </div>
                    {ticket.status === mockStatuses.open && (
                        <Button
                            color="primary"
                            className="close-ticket-btn"
                            onClick={handleCloseTicket}
                        >
                            {t('support_ticket_close')}
                        </Button>
                    )}
                </div>

                <div className="ticket-status-section">
                    <div className={`status-badge ${ticket.status}`}>
                        {t(`support_status_${ticket.status}`)}
                    </div>
                    <div className={`priority-badge ${ticket.priority}`}>
                        {t(`support_priorities_${ticket.priority}`)}
                    </div>
                    <div className="category-badge">
                        {t(`support_categories_${ticket.category}`)}
                    </div>
                </div>

                <div className="ticket-dates">
                    <div>{t('support_ticket_created_at')}: {formatDate(ticket.created_at)}</div>
                    <div>{t('support_ticket_last_update')}: {formatDate(ticket.last_update)}</div>
                </div>

                <div className="support_ticket_history">
                    <h5>{t('support_ticket_history')}</h5>
                    <div className="messages-container">
                        {ticket.messages.map((message, index) => (
                            <div key={message.id} className="message-block">
                                <div className="message-info">
                                    <span className="message-author">{message.author}</span>
                                    <span className="message-time">{formatDate(message.timestamp)}</span>
                                </div>
                                <div className="message-content">
                                    {message.content}
                                </div>
                            </div>
                        ))}
                    </div>

                    {ticket.status === mockStatuses.open && (
                    <div className="message-form">
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder={t('support_ticket_message_placeholder')}
                                    disabled={sending}
                                />
                            </FormGroup>
                            <div className="form-actions">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={sending || !newMessage.trim()}
                                >
                                    {sending ? <Spinner size="sm" /> : t('support_ticket_send')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                )}
                </div>
            </Container>
        </div>
    );
};

export default TicketPage;
