import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import { mockSupportService, mockPriorities } from '../../../mocks/supportData';

const categories = {
    technical: 'Технические проблемы',
    product: 'Поддержка продукта',
    payment: 'Проблема с оплатой',
    other: 'Другое'
};

const CreateTicketModal = ({ isOpen, toggle, onSubmit }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        title: '',
        message: '',
        category: '',
        priority: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.title.trim()) {
            newErrors.title = t('support_form_title_required');
        }
        if (!formData.category) {
            newErrors.category = t('support_form_category_required');
        }
        if (!formData.priority) {
            newErrors.priority = t('support_form_priority_required');
        }
        if (!formData.message.trim()) {
            newErrors.message = t('support_form_message_required');
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit(formData);
            setFormData({
                title: '',
                message: '',
                category: '',
                priority: ''
            });
            setErrors({});
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="md">
            <ModalHeader toggle={toggle}>
                {t('support_form_title')}
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody className="px-4">
                    <FormGroup>
                        <Label for="title">{t('support_form_title_label')}</Label>
                        <Input
                            id="title"
                            name="title"
                            placeholder={t('support_form_title_placeholder')}
                            value={formData.title}
                            onChange={handleChange}
                            invalid={!!errors.title}
                        />
                        {errors.title && (
                            <div className="invalid-feedback d-block">{t('support_form_title_required')}</div>
                        )}
                    </FormGroup>

                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="category" className="required">
                                    {t('support_form_category')}
                                </Label>
                                <Input
                                    id="category"
                                    type="select"
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                    invalid={!!errors.category}
                                >
                                    <option value="">{t('support_form_category_placeholder')}</option>
                                    {Object.entries(categories).map(([value, label]) => (
                                        <option key={value} value={value}>
                                            {t(`support_categories_${value}`)}
                                        </option>
                                    ))}
                                </Input>
                                {errors.category && (
                                    <div className="invalid-feedback d-block">{t('support_form_category_required')}</div>
                                )}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="priority">
                                    {t('support_form_priority')}
                                </Label>
                                <Input
                                    id="priority"
                                    type="select"
                                    name="priority"
                                    value={formData.priority}
                                    onChange={handleChange}
                                    invalid={!!errors.priority}
                                >
                                    <option value="">{t('support_form_priority_placeholder')}</option>
                                    {Object.entries(mockPriorities).map(([value, label]) => (
                                        <option key={value} value={value}>
                                            {t(`support_priorities_${value}`)}
                                        </option>
                                    ))}
                                </Input>
                                {errors.priority && (
                                    <div className="invalid-feedback d-block">{t('support_form_priority_required')}</div>
                                )}
                            </FormGroup>
                        </div>
                    </div>

                    <FormGroup>
                        <Label for="message">{t('support_form_message')}</Label>
                        <Input
                            id="message"
                            type="textarea"
                            name="message"
                            placeholder={t('support_form_message_placeholder')}
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            invalid={!!errors.message}
                        />
                        {errors.message && (
                            <div className="invalid-feedback d-block">{t('support_form_message_required')}</div>
                        )}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={toggle}>
                        {t('support_form_cancel')}
                    </Button>
                    <Button
                        type="submit"
                        style={{ background: '#13b9ae', border: 'none' }}
                    >
                        {t('support_form_submit')}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default CreateTicketModal;
