import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav, 
    NavItem, 
    NavLink, 
    Button
} from 'reactstrap';
import { mockSupportService, mockStatuses, mockPriorities } from '../../mocks/supportData';
import Breadcrumbs from 'components/Common/Breadcrumb';
import CreateTicketModal from './components/CreateTicketModal';
import TicketList from './components/TicketList';
import classnames from 'classnames';
import './support.scss';

const SupportPage = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('recent');
    const [tickets, setTickets] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchTickets = async () => {
        setLoading(true);
        try {
            const response = await mockSupportService.getTickets();
            setTickets(response);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    const handleCreateTicket = async (ticketData) => {
        try {
            await mockSupportService.createTicket(ticketData);
            setIsCreateModalOpen(false);
            fetchTickets();
        } catch (error) {
            console.error('Error creating ticket:', error);
        }
    };

    const toggleCreateModal = () => {
        setIsCreateModalOpen(!isCreateModalOpen);
    };

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="page-content">
            <MetaTags>
                <title>{t('support_title')} | MC</title>
            </MetaTags>

            <Container fluid className="mobile-no-padding">
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div>
                                <h4 className="mb-0">{t('support_title')}</h4>
                                <p className="text-muted mb-0">
                                    {t('support_description')}
                                </p>
                            </div>
                            <Button color="primary" style={{ background: '#13b9ae', border: 'none' }} onClick={toggleCreateModal}>
                                + {t('support_create_ticket')}
                            </Button>
                        </div>

                        <Card>
                            <CardBody>
                                <div className="mb-4">
                                    <Nav pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'recent' })}
                                                onClick={() => toggleTab('recent')}
                                            >
                                                {t('support_tabs_recent')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'open' })}
                                                onClick={() => toggleTab('open')}
                                            >
                                                {t('support_tabs_open')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === 'closed' })}
                                                onClick={() => toggleTab('closed')}
                                            >
                                                {t('support_tabs_closed')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>

                                <TicketList tickets={tickets} loading={loading} activeTab={activeTab} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <CreateTicketModal
                    isOpen={isCreateModalOpen}
                    toggle={toggleCreateModal}
                    onSubmit={handleCreateTicket}
                />
            </Container>
        </div>
    );
};

export default SupportPage;
