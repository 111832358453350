export const mockStatuses = {
    open: 'open',
    closed: 'closed'
};

export const mockPriorities = {
    low: 'low',
    normal: 'normal',
    high: 'high',
    urgent: 'urgent'
};

export const mockCategories = {
    technical: 'technical',
    payment: 'payment',
    product: 'product',
    other: 'other'
};

export const mockTickets = [
    {
        id: 1,
        title: 'Проблема с выводом средств',
        status: mockStatuses.open,
        priority: mockPriorities.high,
        category: mockCategories.payment,
        created_at: '2024-12-10T15:30:00',
        last_update: '2024-12-11T12:59:00',
        messages: [
            {
                id: 1,
                author: 'user@example.com',
                content: 'Не могу вывести средства на карту. Появляется ошибка.',
                timestamp: '2024-12-10T15:30:00'
            },
            {
                id: 2,
                author: 'support@mcapital.com',
                content: 'Пожалуйста, уточните номер транзакции и приложите скриншот ошибки.',
                timestamp: '2024-12-10T16:45:00'
            },
            {
                id: 3,
                author: 'user@example.com',
                content: 'тест',
                timestamp: '2024-12-11T12:59:00'
            }
        ]
    },
    {
        id: 2,
        title: 'Вопрос по инвестиционному портфелю',
        status: 'closed',
        priority: 'normal',
        category: mockCategories.product,
        created_at: '2024-12-09T10:00:00',
        last_update: '2024-12-10T14:20:00',
        messages: [
            {
                id: 3,
                author: 'user@example.com',
                content: 'Как я могу изменить распределение средств в портфеле?',
                timestamp: '2024-12-09T10:00:00'
            },
            {
                id: 4,
                author: 'support@mcapital.com',
                content: 'Для изменения распределения средств перейдите в раздел \'Портфель\' и нажмите кнопку \'Редактировать\'.',
                timestamp: '2024-12-10T14:20:00'
            }
        ]
    },
    {
        id: 3,
        title: 'Техническая проблема с входом',
        status: 'open',
        priority: 'urgent',
        category: mockCategories.technical,
        created_at: '2024-12-11T11:45:00',
        last_update: '2024-12-11T11:45:00',
        messages: [
            {
                id: 5,
                author: 'user@example.com',
                content: 'Не могу войти в аккаунт после обновления пароля',
                timestamp: '2024-12-11T11:45:00'
            }
        ]
    }
];

export const mockSupportService = {
    getTickets: () => Promise.resolve(mockTickets),
    getTicket: (id) => Promise.resolve(mockTickets.find(ticket => ticket.id === parseInt(id))),
    createTicket: (ticketData) => {
        const newTicket = {
            id: mockTickets.length + 1,
            status: mockStatuses.open,
            created_at: new Date().toISOString(),
            last_update: new Date().toISOString(),
            messages: [{
                id: 1,
                author: 'user@example.com',
                content: ticketData.message,
                timestamp: new Date().toISOString()
            }],
            ...ticketData
        };
        mockTickets.push(newTicket);
        return Promise.resolve(newTicket);
    },
    addMessage: (ticketId, message) => {
        const ticket = mockTickets.find(t => t.id === parseInt(ticketId));
        if (ticket) {
            const now = new Date().toISOString();
            ticket.messages.push({
                id: ticket.messages.length + 1,
                author: 'user@example.com',
                content: message,
                timestamp: now
            });
            ticket.last_update = now;
        }
        return Promise.resolve();
    },
    closeTicket: (ticketId) => {
        const ticket = mockTickets.find(t => t.id === parseInt(ticketId));
        if (ticket) {
            ticket.status = mockStatuses.closed;
            ticket.last_update = new Date().toISOString();
        }
        return Promise.resolve();
    }
};
