import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { format } from 'date-fns';
import { mockStatuses, mockPriorities } from '../../../mocks/supportData';

const TicketList = ({ tickets, loading, activeTab }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const filteredTickets = tickets.filter(ticket => {
        switch (activeTab) {
            case 'open':
                return ticket.state === 'open';
            case 'closed':
                return ticket.state === 'closed';
            case 'recent':
            default:
                return true;
        }
    });

    if (loading) {
        return (
            <div className="placeholder-glow">
                {[1, 2, 3].map((i) => (
                    <div key={i} className="mb-3">
                        <div className="placeholder col-12 bg-light rounded" style={{ height: '80px' }} />
                    </div>
                ))}
            </div>
        );
    }

    if (!filteredTickets.length) {
        return (
            <div className="text-center py-5">
                <h5 className="text-muted mb-0">{t('No tickets found')}</h5>
            </div>
        );
    }

    return (
        <ListGroup className="ticket-list">
            {filteredTickets.map((ticket) => (
                <ListGroupItem
                    key={ticket.id}
                    action
                    onClick={() => history.push(`/support/ticket/${ticket.id}`)}
                    className="border rounded mb-2 ticket-item"
                >
                    <div className="d-flex justify-content-between align-items-start">
                        <div>
                            <div className="mb-1">
                                <Badge 
                                    color="light" 
                                    className="me-2 text-secondary"
                                >
                                    #{ticket.number}
                                </Badge>
                                <Badge 
                                    color={ticket.state === 'open' ? 'success' : 'secondary'} 
                                    className="me-2"
                                >
                                    {mockStatuses[ticket.state]}
                                </Badge>
                                <Badge color="info">
                                    {mockPriorities[ticket.priority]}
                                </Badge>
                            </div>
                            <h5 className="mb-1">{ticket.title}</h5>
                        </div>
                        <div className="text-end">
                            <div className="text-muted mb-1">
                                {format(new Date(ticket.created_at), 'dd.MM.yyyy, HH:mm')}
                            </div>
                            <div className="text-muted small">
                                {t('support_messages_count', { count: ticket.messages.length })}
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export default TicketList;
